import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Icon from "components/Icon";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import { LabelS } from "style/components/Typography";
import generateSrcSet from "style/generateSrcSet";
import EpiImage from "types/EpiImage";
import { appendComponentWithNoBr } from "utils/appendComponentWithNoBr";
import { addEditAttributes } from "utils/episerver";
import ucFirst from "utils/ucFirst";

import {
    Container,
    Content,
    Email,
    PhoneSection,
    Description,
    Initials,
    ProfileImage,
    ProfileLink,
    Heading,
    HeadingUrl,
    HeadingArrow,
    Phone,
    Office,
    Offices,
} from "./Profile.styled";
import ProfileProps, { ProfileImageOrInitialsProps } from "./ProfileProps";

const ProfileImageOrInitials = ({
    image,
    initials,
    hideInitials = false,
}: ProfileImageOrInitialsProps): ReactElement => {
    return (
        <>
            {image ? (
                <ProfileImage
                    url={image.url}
                    decorative={image.decorative}
                    alt={image.alt}
                    overlay={image.overlay}
                    srcSet={image.srcSet}
                />
            ) : (
                !hideInitials && <Initials>{initials}</Initials>
            )}
        </>
    );
};

const Profile = ({
    fullName,
    role,
    businessArea,
    email,
    imageUrl,
    initials,
    hideInitials,
    phoneFixed,
    phoneMobile,
    description,
    className,
    editPropertyName,
    url,
    anchorName,
    size = "large",
    showOverlay = false,
    offices,
    gtmClickType,
}: ProfileProps): ReactElement => {
    let image = undefined;

    if (imageUrl) {
        image = {
            url: imageUrl,
            decorative: false,
            alt: fullName,
            overlay: showOverlay ? "UpLeft" : undefined,
        } as EpiImage;

        image.srcSet = generateSrcSet({
            image: imageUrl,
            content: [
                {
                    width: 154,
                    height: 154,
                    breakpoint: BREAKPOINT_NUMBER.XL,
                },
                {
                    width: 147,
                    height: 147,
                    breakpoint: BREAKPOINT_NUMBER.M,
                },
                { width: 84, height: 84 },
            ],
            format: "jpg",
        });
    }
    return (
        <ThemeProvider theme={{ size: size }}>
            <Container
                id={anchorName}
                className={className}
                {...(editPropertyName
                    ? addEditAttributes(editPropertyName)
                    : {})}
            >
                {url ? (
                    <ProfileLink
                        url={url}
                        title={fullName}
                        gtmClickType={gtmClickType}
                    >
                        <ProfileImageOrInitials
                            image={image}
                            initials={initials}
                            hideInitials={hideInitials}
                        />
                    </ProfileLink>
                ) : (
                    <ProfileImageOrInitials
                        image={image}
                        initials={initials}
                        hideInitials={hideInitials}
                    />
                )}
                <Content $noPadding={hideInitials && !image ? true : false}>
                    {url ? (
                        <HeadingUrl to={url} gtmClickType={gtmClickType}>
                            {appendComponentWithNoBr(
                                fullName,
                                <HeadingArrow
                                    icon={"longArrowRight28"}
                                    aria-hidden="true"
                                />,
                            )}
                        </HeadingUrl>
                    ) : (
                        <Heading>{fullName}</Heading>
                    )}
                    {(role || businessArea) && (
                        <LabelS>
                            {role && <span>{role}</span>}
                            {role && businessArea && " "}
                            {businessArea && <span>{businessArea}</span>}
                        </LabelS>
                    )}
                    {email && (
                        <Email
                            email={email}
                            obfuscateChildren={false}
                            hoverSpan={true}
                        >
                            <span>{email}</span>
                        </Email>
                    )}
                    {(phoneMobile || phoneFixed) && (
                        <PhoneSection>
                            {phoneMobile && (
                                <Phone
                                    tel={phoneMobile?.replace(/\s+/g, "")}
                                    gtmClickType={gtmClickType}
                                >
                                    {phoneMobile}
                                </Phone>
                            )}
                            {phoneFixed && (
                                <Phone
                                    tel={phoneFixed?.replace(/\s+/g, "")}
                                    gtmClickType={gtmClickType}
                                >
                                    {phoneFixed}
                                </Phone>
                            )}
                        </PhoneSection>
                    )}
                    {description && (
                        <Description
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    )}
                    {offices && offices.length > 0 && (
                        <Offices>
                            {offices.map((office, index) => (
                                <Office key={`Office${index}`}>
                                    <Icon icon="office28" />
                                    {[ucFirst(office.city), office.addressRow1]
                                        .filter(Boolean)
                                        .join(", ")}
                                </Office>
                            ))}
                        </Offices>
                    )}
                </Content>
            </Container>
        </ThemeProvider>
    );
};

export default React.memo(Profile);
