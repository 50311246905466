import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import Image from "components/Image";
import Link, { Obfuscated } from "components/Link";
import {
    styleBodyM,
    styleHeadingL,
    styleHeadingS,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const iconColor = theme("theme", {
    lightgray: "--icon-on-neutral-primary",
    blue: "--icon-on-blue-primary",
    cyan: "--icon-on-cyan-primary",
});

const hoverColor = theme("theme", {
    lightgray: "--text-on-neutral-link",
    blue: "--text-on-blue-primary-alt",
    cyan: "--text-on-cyan-primary",
});

const linkColor = theme("theme", {
    lightgray: "--text-on-neutral-link",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
});

export const Container = styled.div`
    align-items: flex-start;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
`;

export const Content = styled.div.attrs<{
    $noPadding: boolean;
}>(() => ({}))`
    flex: 1;
    width: calc(100% - 5.25rem);
    ${(props) =>
        !props.$noPadding &&
        theme("size", {
            small: css`
                padding-left: 0.875rem;
            `,
            medium: css`
                padding-left: 1.3125rem;
            `,
            large: css`
                padding-left: 1.3125rem;
            `,
        })};
    ${theme("size", {
        small: css`
            width: calc(100% - 3.5rem);
        `,
        medium: css`
            ${MQ.FROM_M} {
                width: calc(100% - 6.125rem);
            }

            ${MQ.FROM_XL} {
                width: calc(100% - 6.5625rem);
            }
        `,
        large: css`
            ${MQ.FROM_M} {
                width: calc(100% - 9.1875rem);
            }

            ${MQ.FROM_XL} {
                width: calc(100% - 9.625rem);
            }
        `,
    })}
`;

export const Heading = styled.div`
    ${styleHeadingS}
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Email = styled(Obfuscated)`
    ${styleBodyM};
    color: var(${linkColor});
    display: block;
    margin-top: 0.4375rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Phone = styled(Obfuscated)`
    ${styleBodyM};
    color: var(${linkColor});
    margin-top: 0.4375rem;
`;

export const PhoneSection = styled.div`
    ${styleBodyM}
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 0.4375rem;
`;

export const Description = styled.div`
    ${styleBodyM}
    margin-top: 0.4375rem;
`;

const imageOrInitialSize = css`
    height: 5.25rem;
    width: 5.25rem;
    ${theme("size", {
        small: css`
            height: 3.5rem;
            width: 3.5rem;
        `,
        medium: css`
            ${MQ.FROM_M} {
                height: 6.125rem;
                width: 6.125rem;
            }

            ${MQ.FROM_XL} {
                height: 6.5625rem;
                width: 6.5625rem;
            }
        `,
        large: css`
            ${MQ.FROM_M} {
                height: 9.1875rem;
                width: 9.1875rem;
            }

            ${MQ.FROM_XL} {
                height: 9.625rem;
                width: 9.625rem;
            }
        `,
    })}
`;

export const Initials = styled.div`
    ${theme("size", {
        small: styleHeadingS,
        medium: styleHeadingL,
        large: styleHeadingL,
    })}
    align-items: center;
    aspect-ratio: 1;
    background: var(--color-black-o05);
    display: flex;
    justify-content: center;
    text-align: center;
    ${imageOrInitialSize}
`;

export const HeadingArrow = styled(Icon)`
    color: var(${iconColor});
    margin-left: 0.875rem;
    margin-right: 0.875rem;
    transition:
        margin-left 0.2s ease-in-out,
        margin-right 0.2s ease-in-out,
        color 0.2s ease-in-out;
    vertical-align: middle;
`;

export const HeadingUrl = styled(Link)`
    ${styleHeadingS}
    border: none;

    &:hover {
        color: var(${hoverColor});

        ${HeadingArrow} {
            color: var(${hoverColor});
            margin-left: 1.75rem;
            margin-right: 0;
        }
    }
`;

export const ProfileImage = styled(Image)`
    && {
        aspect-ratio: 1;
        ${imageOrInitialSize}
    }
`;

export const ProfileLink = styled(Link)`
    border: none;
    color: inherit;
`;

export const Offices = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0;

    ${MQ.FROM_M} {
        margin-top: 0.875rem;
    }
`;

export const Office = styled.div`
    ${styleBodyM};
    padding-left: 2.1875rem;
    position: relative;

    svg {
        left: 0;
        position: absolute;
    }
`;
